<template>
  <div class="emailReadingOverview mt-20">
    <!-- 传播路径分析 -->
    <div class="propagation-path mb-20">
      <h2 class="first-title mb-20">传播路径分析</h2>
      <div class="map_table">
        <div id="myChart" :style="{ width: '49%', height: '470px' }" />
        <commonTable
          :is-click="false"
          :table-data="diffuseTableData"
          :column-data="diffuseColumn"
          no-pagination
          chart-table
          max-height="450"
        />
        <!--    地图切换      -->
        <div class="fr btn postionMap">
          <el-radio-group v-model="maps" @change="getDiffuseListData">
            <el-radio-button
              label="海外"
              data-track="增值服务-主动传播-邮件传播-邮件阅读情况-传播路径分析-海外"
            />
            <el-radio-button
              label="中国"
              data-track="增值服务-主动传播-邮件传播-邮件阅读情况-传播路径分析-中国"
            />
          </el-radio-group>
        </div>
      </div>
    </div>
    <!-- 阅读邮件用户分布 -->
    <div
      v-if="showYJYHJG || showYJYHQK"
      class="reading-email-user-distribution mb-20"
    >
      <div class="first-title">阅读邮件用户分布</div>
      <div v-if="showYJYHJG">
        <div class="echarts-title">
          <div class="second-title"><span>阅读邮件用户的机构分布</span></div>
          <div class="top">
            <div
              :class="YJYHJGActive === 10 ? 'active' : ''"
              class="mr-8"
              @click="YJYHJGActive = 10"
              data-track="增值服务-主动传播-邮件传播-邮件阅读情况-阅读邮件用户的机构分布-Top10"
            >
              Top10
            </div>
            <div
              :class="YJYHJGActive === 15 ? 'active' : ''"
              class="mr-8"
              @click="YJYHJGActive = 15"
              data-track="增值服务-主动传播-邮件传播-邮件阅读情况-阅读邮件用户的机构分布-Top15"
            >
              Top15
            </div>
            <div
              :class="YJYHJGActive === 20 ? 'active' : ''"
              @click="YJYHJGActive = 20"
              data-track="增值服务-主动传播-邮件传播-邮件阅读情况-阅读邮件用户的机构分布-Top20"
            >
              Top20
            </div>
          </div>
        </div>
        <!-- 阅读邮件用户的机构分布echarts图 -->
        <div
          id="chartYJYHJG"
          :style="{
            width: '100%',
            height: '420px'
          }"
        />
      </div>
      <div v-if="showYJYHQK">
        <div class="echarts-title mt-20">
          <div class="second-title">
            <span
              >阅读邮件用户的期刊分布
              <el-tooltip
                class="item"
                effect="dark"
                content="阅读邮件用户的期刊分布：截至当前时间，阅读邮件的用户，曾发表过文章的期刊的分布"
                placement="top"
              >
                <i class="el-icon-question" />
              </el-tooltip>
            </span>
          </div>
          <div class="top">
            <div
              :class="YJYHQKActive === 10 ? 'active' : ''"
              class="mr-8"
              @click="YJYHQKActive = 10"
              data-track="增值服务-主动传播-邮件传播-邮件阅读情况-阅读邮件用户的期刊分布-Top10"
            >
              Top10
            </div>
            <div
              :class="YJYHQKActive === 15 ? 'active' : ''"
              class="mr-8"
              @click="YJYHQKActive = 15"
              data-track="增值服务-主动传播-邮件传播-邮件阅读情况-阅读邮件用户的期刊分布-Top15"
            >
              Top15
            </div>
            <div
              :class="YJYHQKActive === 20 ? 'active' : ''"
              @click="YJYHQKActive = 20"
              data-track="增值服务-主动传播-邮件传播-邮件阅读情况-阅读邮件用户的期刊分布-Top20"
            >
              Top20
            </div>
          </div>
        </div>
        <!-- 阅读邮件用户的期刊分布echarts图 -->
        <div
          id="chartYJYHQK"
          :style="{
            width: '100%',
            height: '420px'
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Get, ProjectConfig } from 'kjqk-commons/src/common/index.js'
import { geoData, geoWorldData, nameMap } from '@assets/js/utils.js'
import commonTable from '@comp/commonTable'
import { mapState } from 'vuex'
import echarts from 'echarts'
const api = ProjectConfig.emailPush
export default {
  name: 'EmailReadingOverview',
  components: {
    commonTable
  },
  data() {
    return {
      taskId: '',
      diffuseTableData: [], // 传播路径分析data
      diffuseColumn: [
        // 传播路径分析column
        {
          title: '传播路径',
          prop: 'territory',
          notSortable: true
        },
        {
          title: '人数',
          prop: 'count',
          notSortable: true
        },
        {
          title: '占比',
          prop: 'percent'
        }
      ],
      maps: '中国', // 地图切换 默认选中项
      haveWorldData: true,
      publishRegion: '北京', // 起始地址
      publishRegionvalue: '', // 起始地址value
      YJYHJGActive: 20,
      YJYHQKActive: 20,
      YJYHJGData: '',
      YJYHQKData: ''
    }
  },
  computed: {
    ...mapState('incrementService', ['magId']),
    showYJYHJG() {
      return (
        this.YJYHJGData &&
        this.YJYHJGData.openEmailOrgList &&
        this.YJYHJGData.openEmailOrgList.length > 0
      )
    },
    showYJYHQK() {
      return (
        this.YJYHQKData &&
        this.YJYHQKData.openEmailMagList &&
        this.YJYHQKData.openEmailMagList.length > 0
      )
    }
  },
  watch: {
    YJYHJGActive() {
      this.getChartData(
        'YJYHJG',
        { taskId: this.taskId, page: 0, size: this.YJYHJGActive },
        api.GetOpenEmailOrg.url
      )
    },
    YJYHQKActive() {
      this.getChartData(
        'YJYHQK',
        { taskId: this.taskId, page: 0, size: this.YJYHQKActive },
        api.GetOpenEmailMag.url
      )
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustTable)
  },
  mounted() {
    window.addEventListener('resize', this.adjustTable)
  },
  methods: {
    // 窗口大小改变重新渲染echarts
    adjustTable() {
      if (document.getElementById('myChart')) {
        echarts.init(document.getElementById('myChart')).resize()
      }
      if (document.getElementById('chartYJYHJG')) {
        echarts.init(document.getElementById('chartYJYHJG')).resize()
      }
      if (document.getElementById('chartYJYHQK')) {
        echarts.init(document.getElementById('chartYJYHQK')).resize()
      }
    },
    // 获取数据
    getData(item) {
      this.taskId = item.id
      Promise.all([
        this.getDiffuseListData(),
        // 阅读邮件用户的机构分布
        this.getChartData(
          'YJYHJG',
          { taskId: this.taskId, page: 0, size: this.WZYHQKActive },
          api.GetOpenEmailOrg.url
        ),
        // 阅读邮件用户的期刊分布
        this.getChartData(
          'YJYHQK',
          { taskId: this.taskId, page: 0, size: this.YJYHQKActive },
          api.GetOpenEmailMag.url
        )
      ]).then(() => {
        this.$emit('changeScroll')
      })
    },
    // 获取echarts数据
    getChartData(type, params, url) {
      return new Promise((resolve, reject) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            let _this = this
            this.$nextTick(() => {
              if (type === 'YJYHJG') {
                _this.YJYHJGData = data
                if (_this.showYJYHJG) {
                  _this.$nextTick(() => {
                    _this.chartYJYHJG(data)
                  })
                }
              } else if (type === 'YJYHQK') {
                _this.YJYHQKData = data
                if (_this.showYJYHQK) {
                  _this.$nextTick(() => {
                    _this.chartYJYHQK(data)
                  })
                }
              }
            })
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    // 获取传播路径分析列表
    getDiffuseListData(pageParams = {}) {
      let type = this.maps === '海外' ? 2 : 1 // 1是国内 2是国外
      const { page = 1, size = 1000 } = pageParams
      const params = {
        page: page - 1,
        size,
        taskId: this.taskId,
        type: type
      }
      let url = api.GetDiffusePathList.url
      return new Promise((resolve, reject) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            this.diffuseTableData = data
            this.haveWorldData = true
            data.map((item) => {
              if (this.maps === '海外' && item.territory.indexOf('-') !== -1) {
                this.haveWorldData = false
              }
            })
            if (!this.haveWorldData) {
              setTimeout(() => {
                this.initEchart()
              }, 100)
            } else {
              setTimeout(() => {
                this.initEchart(data)
              }, 100)
            }
            this.computedDiffuseTableData()
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    // 去掉传播路径中地区的中国
    computedDiffuseTableData() {
      return this.diffuseTableData.map((it) => {
        if (it && it.territory && it.territory.indexOf('中国-') !== -1) {
          it.territory = it.territory.split('中国-')[1]
        }
      })
    },
    // 地图
    initEchart(data = []) {
      let mapData = ''
      let geoCoordMap = null
      if (this.maps === '海外') {
        // 坐标数据
        mapData = 'world'
        geoCoordMap = geoWorldData
      } else {
        mapData = 'china'
        geoCoordMap = geoData
      }
      const myChartMap = echarts.init(document.getElementById('myChart'))
      // 连线特效
      const planePath =
        'path://M1705.06,1318.313v-89.254l-319.9-221.799l0.073-208.063c0.521-84.662-26.629-121.796-63.961-121.491c-37.332-0.305-64.482,36.829-63.961,121.491l0.073,208.063l-319.9,221.799v89.254l330.343-157.288l12.238,241.308l-134.449,92.931l0.531,42.034l175.125-42.917l175.125,42.917l0.531-42.034l-134.449-92.931l12.238-241.308L1705.06,1318.313z'
      // 处理连线坐标
      const convertData = function (data) {
        const res = []
        for (let i = 0; i < data.length; i += 1) {
          const dataItem = data[i]
          const fromCoord = geoCoordMap[dataItem[0].name]
          const toCoord = geoCoordMap[dataItem[1].name]
          if (fromCoord && toCoord) {
            res.push({
              fromName: dataItem[0].name,
              toName: dataItem[1].name,
              coords: [fromCoord, toCoord]
            })
          }
        }
        return res
      }
      // 数据
      const BJData = data.map((item) => {
        let territoryStr = ''
        if (item.territory) {
          let str = item.territory
          if (this.maps === '海外' && !str.includes('-')) {
            territoryStr = str
          } else {
            let index = str.lastIndexOf('-')
            territoryStr = str.substring(index + 1, str.length - 1)
          }
        }
        this.publishRegionvalue =
          territoryStr === this.publishRegion ? item.count : 95
        const tempVal = [{ name: this.publishRegion }]
        const targetVal = { name: territoryStr, value: item.count }
        tempVal.push(targetVal)
        return tempVal
      })
      const color = ['#a6c84c', '#ffa022', '#46bee9']
      const series = []
      if (this.haveWorldData) {
        BJData.push([
          { name: this.publishRegion },
          { name: this.publishRegion, value: this.publishRegionvalue }
        ])
      }
      const array = [[this.publishRegion, BJData]]
      array.forEach(function (item, i) {
        series.push(
          {
            type: 'lines',
            zlevel: 1,
            effect: {
              show: true,
              period: 6,
              trailLength: 0.7,
              color: '#fff',
              symbolSize: 3
            },
            lineStyle: {
              normal: {
                color: color[i],
                width: 0,
                curveness: 0.2
              }
            },
            data: convertData(item[1])
          },
          {
            type: 'lines',
            zlevel: 2,
            symbol: ['none', 'arrow'],
            symbolSize: 10,
            effect: {
              show: false,
              period: 6,
              trailLength: 0,
              symbol: planePath,
              symbolSize: 15
            },
            lineStyle: {
              color: color[i],
              width: 1,
              opacity: 0.6,
              curveness: 0.2
            },
            data: convertData(item[1])
          },
          {
            type: 'effectScatter',
            coordinateSystem: 'geo',
            zlevel: 3,
            rippleEffect: {
              brushType: 'stroke'
            },
            label: {
              show: true,
              position: 'right',
              formatter: '{b}'
            },
            symbolSize: function (val) {
              return val[2] / 100
            },
            itemStyle: {
              color: color[i]
            },
            data: item[1].map(function (dataItem) {
              return {
                name: dataItem[1].name,
                value: geoCoordMap[dataItem[1].name]
                  ? geoCoordMap[dataItem[1].name].concat([dataItem[1].value])
                  : ''
              }
            })
          }
        )
      })
      myChartMap.setOption(
        {
          // 新建一个地理坐标系 geo ，
          geo: {
            map: mapData, // 地图类型为中国地图,要是海外那就是world,要是省市区：例如beijing、shanghai
            zoom: 1.2,
            itemStyle: {
              // 定义样式
              normal: {
                // 普通状态下的样式
                areaColor: '#6699CC',
                borderColor: '#fff'
              },
              emphasis: {
                // 高亮状态下的样式
                areaColor: '#e9fbf1'
              }
            }
          },
          nameMap: nameMap, // 中英文名字转换
          // hover显示目标数据
          tooltip: {
            trigger: 'item',
            formatter: function (params) {
              let res
              if (params.data.value) {
                res = '<p>人数' + params.data.value[2] + '</p>'
              }

              return res
            },
            // tooltip的trigger的值可以有'item'、'axis'。
            // 'item':数据项图形触发，主要在散点图，饼图等无类目轴的图表中使用。
            // 'axis':坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
            textStyle: {
              align: 'left'
            }
          },
          toolbox: {
            show: true,
            feature: {
              myFull: {
                show: true,
                title: '全屏查看',
                icon:
                  'path://M432.45,595.444c0,2.177-4.661,6.82-11.305,6.82c-6.475,0-11.306-4.567-11.306-6.82s4.852-6.812,11.306-6.812C427.841,588.632,432.452,593.191,432.45,595.444L432.45,595.444z M421.155,589.876c-3.009,0-5.448,2.495-5.448,5.572s2.439,5.572,5.448,5.572c3.01,0,5.449-2.495,5.449-5.572C426.604,592.371,424.165,589.876,421.155,589.876L421.155,589.876z M421.146,591.891c-1.916,0-3.47,1.589-3.47,3.549c0,1.959,1.554,3.548,3.47,3.548s3.469-1.589,3.469-3.548C424.614,593.479,423.062,591.891,421.146,591.891L421.146,591.891zM421.146,591.891',
                onclick: () => {
                  const element = document.getElementById('myChart')
                  const myEcharts = echarts.init(element)
                  window.onresize = myEcharts.resize
                  if (element.requestFullScreen) {
                    // HTML W3C 提议
                    element.requestFullScreen()
                  } else if (element.msRequestFullscreen) {
                    // IE11
                    element.msRequestFullScreen()
                  } else if (element.webkitRequestFullScreen) {
                    // Webkit (works in Safari5.1 and Chrome 15)
                    element.webkitRequestFullScreen()
                  } else if (element.mozRequestFullScreen) {
                    // Firefox (works in nightly)
                    element.mozRequestFullScreen()
                  }
                  // 退出全屏
                  if (element.requestFullScreen) {
                    document.exitFullscreen()
                  } else if (element.msRequestFullScreen) {
                    document.msExitFullscreen()
                  } else if (element.webkitRequestFullScreen) {
                    document.webkitCancelFullScreen()
                  } else if (element.mozRequestFullScreen) {
                    document.mozCancelFullScreen()
                  }
                }
              }
            }
          },
          // 图表背景色
          backgroundColor: '#2644ca',
          // 标志颜色
          color: 'red',
          // 新建散点图series
          series: series,
          // // 添加视觉映射组件
          visualMap: {
            type: 'continuous', // 连续型
            min: 0, // 值域最小值，必须参数
            max: 100, // 值域最大值，必须参数
            calculable: true, // 是否启用值域漫游
            inRange: {
              color: ['yellow', 'red']
              // 指定数值从低到高时的颜色变化
            },
            itemWidth: 10,
            itemHeight: 92,
            textStyle: {
              color: '#fff' // 值域控件的文本颜色
            }
          }
        },
        true
      )
    },
    // 阅读邮件用户的机构分布echarts图
    chartYJYHJG(data) {
      if (!document.getElementById('chartYJYHJG')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartYJYHJG'))
      const titleArr = []
      const countArr = []
      if (data.openEmailOrgList.length > 0) {
        data.openEmailOrgList.map((item) => {
          titleArr.push(item.orgName)
          countArr.push(item.count)
        })
      }
      myChart.setOption(
        {
          color: ['#0070C0'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          xAxis: [
            {
              type: 'category',
              data: titleArr,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                rotate: 30,
                formatter: function (value) {
                  var res = value
                  if (res.length > 6) {
                    res = res.substring(0, 6) + '..'
                  }
                  return res
                }
              }
            }
          ],
          grid: {
            left: '10%',
            bottom: '12%',
            top: '3%'
          },
          yAxis: [
            {
              type: 'value',
              minInterval: 1
            }
          ],
          series: [
            {
              name: '数量',
              type: 'bar',
              data: countArr,
              barWidth: 20
            }
          ]
        },
        true
      )
    },
    // 阅读邮件用户的期刊分布echarts图
    chartYJYHQK(data) {
      if (!document.getElementById('chartYJYHQK')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartYJYHQK'))
      const titleArr = []
      const countArr = []
      if (data.openEmailMagList.length > 0) {
        data.openEmailMagList.map((item) => {
          titleArr.push(item.magName)
          countArr.push(item.count)
        })
      }
      myChart.setOption(
        {
          color: ['#0070C0'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          xAxis: [
            {
              type: 'category',
              data: titleArr,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                rotate: 30,
                formatter: function (value) {
                  var res = value
                  if (res.length > 6) {
                    res = res.substring(0, 6) + '..'
                  }
                  return res
                }
              }
            }
          ],
          grid: {
            left: '10%',
            bottom: '12%',
            top: '3%'
          },
          yAxis: [
            {
              type: 'value',
              minInterval: 1
            }
          ],
          series: [
            {
              name: '数量',
              type: 'bar',
              data: countArr,
              barWidth: 20
            }
          ]
        },
        true
      )
    }
  }
}
</script>
<style lang="scss">
.propagation-path {
  .postionMap {
    .is-active .el-radio-button__inner {
      background-color: white;
      color: #265cca;
    }
    .el-radio-button__inner {
      color: white;
      background: #265cca;
      box-sizing: border-box;
      height: 24px;
      line-height: 24px;
      border: 1px solid #ccc;
      padding: 0 20px;
      width: auto;
      text-align: center;
      border-radius: 30px !important;
      font-size: 14px;
    }
  }
}
</style>
<style lang="scss" scoped>
// 传播路径分析
.propagation-path {
  .map_table {
    position: relative;
    background: #265cca;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .postionMap {
      position: absolute;
      top: 12px;
      left: 12px;
      z-index: 1;
    }
    .btn.postionMap .el-radio-button {
      top: 0;
      margin-right: 10px;
    }
  }
  .center_table {
    width: 49%;
    height: 470px;
    background: #fff;
    margin: 10px !important;
  }
}
</style>
