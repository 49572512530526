<template>
  <div v-loading="loading" class="email-push">
    <!-- 推送详情 -->
    <div class="first-title mb-20">推送数据概览</div>
    <ul class="push-data">
      <li>
        <p class="data data1" v-text="pushInfoData.total || 0" />
        <p class="text">推送总次数</p>
      </li>
      <li>
        <p class="data data3" v-text="pushInfoData.openCount || 0" />
        <p class="text">邮件阅读总次数</p>
      </li>
      <li>
        <p class="data data2" v-text="pushInfoData.totalOpenCount || 0" />
        <p class="text">邮件阅读总人数</p>
      </li>
      <!-- <li>
        <p class="data data3" v-text="pushInfoData.totalReadCount || 0" />
        <p class="text">阅读文章用户总量</p>
      </li> -->
    </ul>
    <!-- 任务列表 -->
    <div class="first-title mt-20 mb-20 fl">邮件推送列表</div>
    <commonTable
      ref="taskList"
      :class="taskTableStyle"
      :table-data="taskTableData"
      :column-data="taskColumn"
      :init-obj="taskPageObj"
      show-list-btn
      no-serial
      show-choose
      is-click
      @onRowClick="handleRowClick"
      @onChangePageData="
        (pageObj) => {
          getPushTaskListData(pageObj)
        }
      "
    />
    <div
      class="emailPush-card mt-20"
      ref="fixedHeaderRoot"
      id="fixedHeaderRoot"
      :class="headerFixed ? 'isFixed' : ''"
    >
      <div
        class="isFixed-content"
        :style="{ width: headerFixed ? contentWidth : '' }"
      >
        <div class="emailPush-tabs">
          <div
            v-for="(item, index) in cardList"
            :class="activeTab === index ? 'active-tab' : ''"
            :key="index"
            class="emailPush-tab"
            @click="changeTab(index, 'click')"
            :data-track="`增值服务-主动传播-邮件传播-${item}`"
          >
            {{ item }}
          </div>
        </div>
        <div class="tab-line" />
      </div>
      <pushResults
        v-show="activeTab === 0"
        ref="pushResults"
        @changeScroll="changeScroll"
      />
      <pushUserData
        v-show="activeTab === 1"
        ref="pushUserData"
        @changeScroll="changeScroll"
      />
      <emailReadingOverview
        v-show="activeTab === 2"
        ref="emailReadingOverview"
        @changeScroll="changeScroll"
      />
      <whitelistAnalysis
        v-show="activeTab === 3"
        ref="whitelistAnalysis"
        @changeScroll="changeScroll"
      />
    </div>
  </div>
</template>

<script>
import commonTable from '@comp/commonTable'
import pushResults from './components/pushResults'
import pushUserData from './components/pushUserData'
import emailReadingOverview from './components/emailReadingOverview'
import whitelistAnalysis from './components/whitelistAnalysis'
import { Get, ProjectConfig } from 'kjqk-commons/src/common/index.js'
import { mapState } from 'vuex'
import china from '@assets/js/china.js'
import world from '@assets/js/world.js'
const api = ProjectConfig.emailPush
export default {
  name: 'PushResults',
  components: {
    commonTable,
    pushResults,
    pushUserData,
    emailReadingOverview,
    whitelistAnalysis
  },
  data() {
    return {
      loading: true,
      china: china,
      world: world,
      cardList: ['推送结果', '推送用户概况', '邮件阅读情况', '白名单推送结果'],
      activeTab: 0,
      detailsData: '',
      // 当前选中的任务Id
      // 推送任务列表分页信息
      taskPageObj: {
        page: 1,
        size: 10
      },
      taskTableData: null, // 推送任务列表
      taskColumn: [
        {
          title: '推送标题',
          prop: 'topic',
          notSortable: true
        },
        {
          title: '推送时间',
          prop: 'pushTime',
          notSortable: true,
          width: '180px'
        },
        {
          title: '邮件送达数量',
          prop: 'pushSuccessCount',
          notSortable: true,
          width: '180px'
        },
        {
          title: '邮件阅读次数',
          prop: 'emailOPenCount',
          notSortable: true,
          width: '180px'
        },
        {
          title: '邮件阅读人数',
          prop: 'onlyEmailOPenCount',
          notSortable: true,
          width: '180px'
        },
        {
          title: '打开率',
          prop: 'emailOPenPercent',
          notSortable: true,
          width: '120px'
        }
      ],
      haveTaskId: false,
      currentTask: '',
      pushInfoData: {
        total: '',
        totalOpenCount: '',
        openCount: ''
      },
      headerFixed: '',
      offsetTop: '',
      offsetHeight: '',
      contentWidth: '',
      tabType: ''
    }
  },
  computed: {
    ...mapState('incrementService', ['magId']),
    taskTableStyle() {
      return this.taskTableData && this.taskTableData.length > 0
        ? 'task-list'
        : 'task-list-mt-20'
    }
  },
  watch: {
    magId(n) {
      this.activeTab = 0
      if (n) {
        this.getPushTaskListData()
        this.getAllTaskInfoData()
      } else {
        this.initData()
      }
    },
    // activeTab() {
    //   this.getCurrenttabData(this.currentTask)
    // },
    currentTask() {
      this.getCurrenttabData(this.currentTask)
    }
  },
  mounted() {
    if (this.magId) {
      this.getPushTaskListData()
      this.getAllTaskInfoData()
    } else {
      this.initData()
    }
    this.$nextTick(() => {
      this.contentWidth =
        document.getElementsByClassName('email-push')[0].clientWidth - 40 + 'px'
    })
    window.addEventListener('scroll', this.handleScroll, true)
    window.addEventListener('resize', this.adjustTable)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustTable)
  },
  methods: {
    adjustTable() {
      this.contentWidth =
        document.getElementsByClassName('email-push')[0].clientWidth - 40 + 'px'
    },
    handleScroll() {
      // 得到页面滚动的距离
      if (this.$refs.fixedHeaderRoot) {
        let offsetTop = this.$refs.fixedHeaderRoot.getBoundingClientRect().top
        this.headerFixed = offsetTop < 150
      }
    },
    changeScroll() {
      this.$nextTick(() => {
        let offsetTop = this.$refs.fixedHeaderRoot.getBoundingClientRect().top
        if (this.tabType === 'click' && offsetTop < 150) {
          let ele = document.getElementsByClassName('main-content')[0]
          ele.scrollTop = 260
          this.tabType = ''
        }
      })
    },
    initData() {
      this.pushInfoData = {
        total: '',
        totalOpenCount: '',
        openCount: ''
      }
      this.haveTaskId = false
      this.currentTask = ''
      this.taskTableData = []
      this.loading = false
    },
    // 推送列表获取
    getPushTaskListData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        page: page - 1,
        size,
        magId: this.magId
      }
      let url = api.GetPushTaskList.url
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.taskPageObj = {
            page,
            size,
            total: totalElements
          }
          this.pushInfoData.total = totalElements
          this.taskTableData = content
          if (this.taskTableData && this.taskTableData.length > 0) {
            this.$nextTick(() => {
              this.$refs.taskList.handleRowClick(this.taskTableData[0])
            })
            this.haveTaskId = true
            this.changeTab(this.activeTab)
          } else {
            this.haveTaskId = false
          }
        }
      }).finally(() => {
        setTimeout(() => {
          this.loading = false
        }, 500)
      })
    },
    // 切换tab键
    changeTab(index, type) {
      this.activeTab = index
      this.tabType = type
      this.$nextTick(() => {
        if (this.activeTab === 0) {
          this.$refs.pushResults.adjustTable()
        } else if (this.activeTab === 1) {
          this.$refs.pushUserData.adjustTable()
        } else if (this.activeTab === 2) {
          this.$refs.emailReadingOverview.adjustTable()
        }
      })
    },
    // 点击每一行
    handleRowClick(id, item) {
      this.currentTask = item
      this.getCurrenttabData(this.currentTask)
    },
    // 获取当前tab下数据
    getCurrenttabData(item) {
      this.$nextTick(() => {
        this.$refs.pushResults.getData(item)
        this.$refs.pushUserData.getData(item)
        this.$refs.emailReadingOverview.getData(item)
        this.$refs.whitelistAnalysis.getData(item)
      })
    },
    // 获取所有任务用户行为信息
    getAllTaskInfoData() {
      const params = {
        magId: this.magId
      }
      let url = api.GetAllTaskInfo.url
      Get(url, { params }).then((res) => {
        this.pushInfoData.totalOpenCount = res?.data?.totalOpenCount || 0
        this.pushInfoData.openCount = res?.data?.openCount || 0
      })
    }
  }
}
</script>
<style lang="scss">
.email-push {
  .taskTable-data-list {
    .el-table__empty-block {
      min-height: 40px;
    }
    .el-table__empty-text {
      line-height: 40px;
    }
  }
  .postionMap {
    .is-active .el-radio-button__inner {
      background-color: white;
      color: #3e55ad;
    }
    .el-radio-button__inner {
      color: white;
      background: #3e55ad;
      box-sizing: border-box;
      height: 24px;
      line-height: 24px;
      border: 1px solid #ccc;
      padding: 0 20px;
      width: auto;
      text-align: center;
      border-radius: 30px !important;
      font-size: 14px;
    }
  }
  .el-table {
    background-color: #fff;
    overflow-y: auto;
  }
  .countTable {
    .el-table thead.is-group th,
    .el-table__header-wrapper tbody td,
    .el-table th.is-leaf,
    .el-table__footer-wrapper tbody td {
      background-color: #f1f2f4 !important;
    }
    .el-table .el-table__header thead th .cell {
      display: inline;
    }
  }
}
</style>
<style scoped lang="scss">
.email-push {
  // height: 100%;
  // margin: 0 auto;
  padding: 20px;
  .task-list-mt-20 {
    // margin: 20px -10px 20px 0 !important;
  }
  .task-list {
    // margin: 0 -10px 20px 0 !important;
    margin-bottom: 20px;
  }
  // 推送详情
  .push-data {
    display: flex;
    justify-content: space-around;
    font-size: 14px;
    color: #999;
    text-align: left;
    line-height: 30px;
    background: #f5f7fd;
    padding: 14px 0;
    margin: 20px 0 0 0;
    li {
      text-align: center;
      .data {
        line-height: 28px;
        font-size: 22px;
        margin: 0;
      }
      .data1 {
        color: #ec5463;
      }
      .data2 {
        color: #0ba9e1;
      }
      .data3 {
        color: #2db897;
      }
      .text {
        line-height: 24px;
        margin: 0;
      }
    }
  }
  //tab键
  .emailPush-card {
    .emailPush-tabs {
      display: flex;
      .emailPush-tab {
        width: 102px;
        height: 24px;
        font-size: 12px;
        color: #6c6c6c;
        background: #cccccc;
        line-height: 24px;
        text-align: center;
        margin-right: 10px;
        cursor: pointer;
      }
      .active-tab {
        height: 30px;
        line-height: 30px;
        color: #fff;
        background: #4055ae;
      }
    }
    .tab-line {
      background: #4055ae;
      height: 1px;
    }
  }
  .isFixed {
    .isFixed-content {
      position: fixed;
      top: 80px;
      z-index: 9;
      background: #fff;
      padding-top: 20px;
    }
  }
}
.mt-36 {
  margin-top: 36px;
}
</style>
