<template>
  <div class="pushUserData mt-20">
    <!-- 推送用户概况 -->
    <div class="push-user-profile mb-20">
      <div class="second-title"><span>推送用户概况</span></div>
      <ul class="push-user-info">
        <li class="li_1">
          <p class="data" v-text="basicInfo.countOrg || 0" />
          <p class="text">机构覆盖量</p>
        </li>
        <li class="li_2">
          <p class="data" v-text="basicInfo.countMag || 0" />
          <p class="text">期刊覆盖量</p>
        </li>
        <li class="li_3">
          <p class="data" v-text="basicInfo.countWhiteListAuthor || 0" />
          <p class="text">白名单用户人数</p>
        </li>
      </ul>
    </div>
    <!-- 推送用户的来源分布 -->
    <div v-if="showTSYHLY" class="push-user-source mb-20">
      <div class="second-title"><span>推送用户的来源分布</span></div>
      <div
        id="chartTSYHLY"
        :style="{
          width: '100%',
          height: '420px'
        }"
      />
    </div>
    <!-- 推送用户的机构分布 -->
    <div v-if="showTSYHJG" class="push-user-mechanism mb-20">
      <div class="echarts-title">
        <div class="second-title"><span>推送用户的机构分布</span></div>
        <div class="top">
          <div
            :class="TSYHJGActive === 10 ? 'active' : ''"
            class="mr-8"
            @click="TSYHJGActive = 10"
            data-track="增值服务-主动传播-邮件传播-推送用户概况-推送用户的机构分布-Top10"
          >
            Top10
          </div>
          <div
            :class="TSYHJGActive === 15 ? 'active' : ''"
            class="mr-8"
            @click="TSYHJGActive = 15"
            data-track="增值服务-主动传播-邮件传播-推送用户概况-推送用户的机构分布-Top15"
          >
            Top15
          </div>
          <div
            :class="TSYHJGActive === 20 ? 'active' : ''"
            @click="TSYHJGActive = 20"
            data-track="增值服务-主动传播-邮件传播-推送用户概况-推送用户的机构分布-Top20"
          >
            Top20
          </div>
        </div>
      </div>
      <div
        id="chartTSYHJG"
        :style="{
          width: '100%',
          height: '420px'
        }"
      />
    </div>
    <!-- 推送用户的期刊分布 -->
    <div v-if="showTSYHQK" class="push-user-periodical mb-20">
      <div class="echarts-title">
        <div class="second-title">
          <span
            >推送用户的期刊分布
            <el-tooltip
              class="item"
              effect="dark"
              content="推送用户的期刊分布：推送用户曾发表过文章的期刊的分布"
              placement="top"
            >
              <i class="el-icon-question" />
            </el-tooltip>
          </span>
        </div>
        <div class="top">
          <div
            :class="TSYHQKActive === 10 ? 'active' : ''"
            class="mr-8"
            @click="TSYHQKActive = 10"
            data-track="增值服务-主动传播-邮件传播-推送用户概况-推送用户的期刊分布-Top10"
          >
            Top10
          </div>
          <div
            :class="TSYHQKActive === 15 ? 'active' : ''"
            class="mr-8"
            @click="TSYHQKActive = 15"
            data-track="增值服务-主动传播-邮件传播-推送用户概况-推送用户的期刊分布-Top15"
          >
            Top15
          </div>
          <div
            :class="TSYHQKActive === 20 ? 'active' : ''"
            @click="TSYHQKActive = 20"
            data-track="增值服务-主动传播-邮件传播-推送用户概况-推送用户的期刊分布-Top20"
          >
            Top20
          </div>
        </div>
      </div>
      <div
        id="chartTSYHQK"
        :style="{
          width: '100%',
          height: '420px'
        }"
      />
    </div>
  </div>
</template>

<script>
import { Get, ProjectConfig } from 'kjqk-commons/src/common/index.js'
import echarts from 'echarts'
import { mapState } from 'vuex'
const api = ProjectConfig.emailPush
export default {
  name: 'PushUserData',
  data() {
    return {
      taskId: '',
      basicInfo: {},
      TSYHJGActive: 20,
      TSYHQKActive: 20,
      TSYHLYData: '',
      TSYHJGData: '',
      TSYHQKData: ''
    }
  },
  computed: {
    ...mapState('incrementService', ['magId']),
    showTSYHLY() {
      return (
        this.TSYHLYData &&
        this.TSYHLYData.sourceTypList &&
        this.TSYHLYData.sourceTypList.length > 0
      )
    },
    showTSYHJG() {
      return (
        this.TSYHJGData &&
        this.TSYHJGData.orgList &&
        this.TSYHJGData.orgList.length > 0
      )
    },
    showTSYHQK() {
      return (
        this.TSYHQKData &&
        this.TSYHQKData.magList &&
        this.TSYHQKData.magList.length > 0
      )
    }
  },
  watch: {
    TSYHJGActive() {
      this.getChartData(
        'TSYHJG',
        { taskId: this.taskId, page: 0, size: this.TSYHJGActive },
        api.GetPushMagAuthorOrg.url
      )
    },
    TSYHQKActive() {
      this.getChartData(
        'TSYHQK',
        { taskId: this.taskId, page: 0, size: this.TSYHQKActive },
        api.GetMag.url
      )
    }
  },
  mounted() {
    window.addEventListener('resize', this.adjustTable)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustTable)
  },
  methods: {
    // 窗口大小改变重新渲染echarts
    adjustTable() {
      if (document.getElementById('chartTSYHLY')) {
        echarts.init(document.getElementById('chartTSYHLY')).resize()
      }
      if (document.getElementById('chartTSYHJG')) {
        echarts.init(document.getElementById('chartTSYHJG')).resize()
      }
      if (document.getElementById('chartTSYHQK')) {
        echarts.init(document.getElementById('chartTSYHQK')).resize()
      }
    },
    getData(item) {
      this.taskId = item.id
      Promise.all([
        this.getBasicData(),
        // 推送用户的来源分布
        this.getChartData(
          'TSYHLY',
          { taskId: this.taskId },
          api.GetSourceType.url
        ),
        // 推送用户的机构分布
        this.getChartData(
          'TSYHJG',
          { taskId: this.taskId, page: 0, size: this.TSYHJGActive },
          api.GetPushMagAuthorOrg.url
        ),
        // 推送用户的期刊分布
        this.getChartData(
          'TSYHQK',
          { taskId: this.taskId, page: 0, size: this.TSYHQKActive },
          api.GetMag.url
        )
      ]).then(() => {
        this.$emit('changeScroll')
      })
    },
    // 推送用户概况
    getBasicData() {
      const params = {
        taskId: this.taskId
      }
      let url = api.GetSum.url
      return new Promise((resolve, reject) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            this.basicInfo = data
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    // 获取echarts数据
    getChartData(type, params, url) {
      return new Promise((resolve, reject) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            let _this = this
            this.$nextTick(() => {
              if (type === 'TSYHLY') {
                _this.TSYHLYData = data
                if (_this.showTSYHLY) {
                  _this.$nextTick(() => {
                    _this.chartTSYHLY(data)
                  })
                }
              } else if (type === 'TSYHJG') {
                _this.TSYHJGData = data
                if (_this.showTSYHJG) {
                  _this.$nextTick(() => {
                    _this.chartTSYHJG(data)
                  })
                }
              } else if (type === 'TSYHQK') {
                _this.TSYHQKData = data
                if (_this.showTSYHQK) {
                  _this.$nextTick(() => {
                    _this.chartTSYHQK(data)
                  })
                }
              }
            })
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    // 推送用户的来源分布
    chartTSYHLY(data) {
      if (!document.getElementById('chartTSYHLY')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartTSYHLY'))
      const titleArr = []
      const countArr = []
      if (data.sourceTypList.length === 0) {
        return false
      }
      data.sourceTypList.map((item) => {
        titleArr.push(item.sourceType)
        countArr.push(item.count)
      })
      myChart.setOption(
        {
          color: '#0070C0',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          xAxis: [
            {
              type: 'category',
              data: titleArr,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                rotate: 30,
                formatter: function (value) {
                  var res = value
                  if (res.length > 6) {
                    res = res.substring(0, 6) + '..'
                  }
                  return res
                }
              }
            }
          ],
          grid: {
            left: '10%',
            bottom: '12%',
            top: '2%'
          },
          yAxis: [
            {
              type: 'value',
              minInterval: 1
            }
          ],
          series: [
            {
              name: '数量',
              type: 'bar',
              data: countArr,
              barWidth: 20
            }
          ]
        },
        true
      )
    },
    // 推送用户的机构分布echarts图
    chartTSYHJG(data) {
      if (!document.getElementById('chartTSYHJG')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartTSYHJG'))
      const titleArr = []
      const countArr = []
      if (data.orgList.length === 0) {
        return false
      }
      data.orgList.map((item) => {
        titleArr.push(item.orgName)
        countArr.push(item.count)
      })
      myChart.setOption(
        {
          color: ['#0070C0'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          xAxis: [
            {
              type: 'category',
              data: titleArr,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                rotate: 30,
                formatter: function (value) {
                  var res = value
                  if (res.length > 6) {
                    res = res.substring(0, 6) + '..'
                  }
                  return res
                }
              }
            }
          ],
          grid: {
            left: '10%',
            bottom: '12%',
            top: '3%'
          },
          yAxis: [
            {
              type: 'value',
              minInterval: 1
            }
          ],
          series: [
            {
              name: '数量',
              type: 'bar',
              data: countArr,
              barWidth: 20
            }
          ]
        },
        true
      )
    },
    // 推送用户的期刊分布echarts图
    chartTSYHQK(data) {
      if (!document.getElementById('chartTSYHQK')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartTSYHQK'))
      const titleArr = []
      const countArr = []
      if (data.magList.length === 0) {
        return false
      }
      data.magList.map((item) => {
        titleArr.push(item.magName)
        countArr.push(item.count)
      })
      myChart.setOption(
        {
          color: ['#0070C0'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          xAxis: [
            {
              type: 'category',
              data: titleArr,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                rotate: 30,
                formatter: function (value) {
                  var res = value
                  if (res.length > 6) {
                    res = res.substring(0, 6) + '..'
                  }
                  return res
                }
              }
            }
          ],
          grid: {
            left: '10%',
            bottom: '12%',
            top: '3%'
          },
          yAxis: [
            {
              type: 'value',
              minInterval: 1
            }
          ],
          series: [
            {
              name: '数量',
              type: 'bar',
              data: countArr,
              barWidth: 20
            }
          ]
        },
        true
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.pushUserData {
  .push-user-profile {
    .push-user-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      li {
        width: 31.7%;
        height: 41px;
        padding: 15px 0;
        text-align: center;
        .data {
          font-size: 18px;
          font-family: FZYOUHS_508R--GB1-0;
        }
        .text {
          color: #6c6c6c;
          font-size: 10px;
        }
      }
      .li_1 {
        background: #e6f4eb;
        color: #39b54a;
      }
      .li_2 {
        background: #f5f7fd;
        color: #ff00ff;
      }
      .li_3 {
        background: #f7f3f0;
        color: #f7931e;
      }
    }
  }
}
</style>
